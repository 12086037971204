<template>
    <div class="search">
        <el-form :inline="true" :model="search" size="small">
            <el-form-item label="收费方式">
                <el-select v-model="search.type" placeholder="请选择收费方式" style="width:100px">
                    <el-option label="现场刷卡" value="appoint"></el-option>
                    <el-option label="医院代扣" value="daikou"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="姓名">
                <el-input v-model="search.name" placeholder="请输入姓名" clearable />
            </el-form-item>
            <el-form-item label="身份证号">
                <el-input v-model="search.idnum" placeholder="请输入身份证号" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="getData">查询</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            search: { type: 'appoint' },
        }
    },
    methods: {
        async getData() {
            this.loading = true
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.search {
    padding: 10px;
    background-color: #FAFAFA;
    border: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }
}

</style>